var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    icon: "",
                                    "e2e-snapshot-delete": "",
                                    color: "error",
                                  },
                                },
                                { ...tooltip, ...dialog }
                              ),
                              [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("Delete snapshot")])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.deleteSnapshotDialog,
        callback: function ($$v) {
          _vm.deleteSnapshotDialog = $$v
        },
        expression: "deleteSnapshotDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center w-100" },
              [
                _c("v-icon", [_vm._v("delete")]),
                _c(
                  "span",
                  { staticClass: "text-uppercase secondary--text ml-1" },
                  [_vm._v("delete snapshot")]
                ),
                _c("v-spacer"),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("div", { staticClass: "mt-3" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Snapshot Name"),
                ]),
                _vm._v(" : " + _vm._s(_vm.snapshotData.long_id) + " "),
              ]),
              _c("div", { staticClass: "mt-3" }, [
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v("Snapshot Timestamp"),
                ]),
                _vm._v(" : "),
                _vm.snapshotData.snapshot_timestamp
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateTimeToHuman")(
                            _vm.snapshotData.snapshot_timestamp
                          )
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-alert",
                {
                  staticClass: "mt-5",
                  attrs: {
                    outlined: "",
                    color: "error",
                    prominent: "",
                    type: "warning",
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("Here is what happens when you delete a snapshot"),
                  ]),
                  _c("ul", { staticClass: "mt-3" }, [
                    _c("li", [
                      _c("span", { staticClass: "subtitle-2" }, [
                        _vm._v(
                          "The contents (files, tables, and application) of the snapshot will be deleted permanently."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-divider", {
                    staticClass: "my-4 secondary",
                    staticStyle: { opacity: "0.22" },
                  }),
                  _c("v-checkbox", {
                    staticClass: "secondary--text",
                    attrs: {
                      color: "error",
                      label: "I understand",
                      "e2e-snapshot-delete-confirm": "",
                    },
                    model: {
                      value: _vm.agreeBox,
                      callback: function ($$v) {
                        _vm.agreeBox = $$v
                      },
                      expression: "agreeBox",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-actions", [
            _c("div", { staticClass: "d-flex flex-column w-100" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.deleteSnapshotDialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                  _c("DeleteButton", {
                    attrs: {
                      buttonName: "delete",
                      objectType: _vm.nuvolosObjectTypes.SNAPSHOT,
                      apiURL: "/snapshots/" + _vm.$props.snapshotData.snid,
                      id: parseInt(_vm.$props.snapshotData.snid, 10),
                      getterString: "snapshotStore/snapshotLongNameById",
                      higherLevelId: parseInt(_vm.$route.params.iid, 10),
                      routeAfter:
                        _vm.$props.snapshotData.snid.toString() ===
                        _vm.$route.params.snid.toString()
                          ? {
                              name: "snapshot-overview",
                              params: {
                                oid: _vm.$route.params.oid,
                                sid: _vm.$route.params.sid,
                                iid: _vm.$route.params.iid,
                                snid: _vm.developmentSnapshot.snid,
                              },
                            }
                          : null,
                      fetchString: "instanceStore/fetchInstanceSnapshots",
                      disabled: !_vm.agreeBox,
                      isInsideDialog: true,
                      objectName: _vm.$props.snapshotData.long_id,
                      isAsyncDeletion: true,
                    },
                    on: {
                      error: function ($event) {
                        _vm.error = $event.error
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-5",
                      attrs: {
                        value: _vm.error,
                        color: "error",
                        prominent: "",
                        type: "error",
                        text: "",
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Snapshot Deletion Error"),
                        ]),
                        _vm._v(
                          " An error has occurred and the snapshot could not be deleted. Please try again later and if the problem persists contact support@alphacruncher.com "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }